var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",staticClass:"btn base-button",class:[
    { 'rounded-circle': _vm.round },
    { 'btn-block': _vm.block },
    { 'btn-wd': _vm.wide },
    { 'btn-icon btn-fab': _vm.icon },
    ( _obj = {}, _obj[("btn-" + _vm.type)] = _vm.type && !_vm.outline, _obj ),
    ( _obj$1 = {}, _obj$1[("btn-" + _vm.size)] = _vm.size, _obj$1 ),
    ( _obj$2 = {}, _obj$2[("btn-outline-" + _vm.type)] = _vm.outline && _vm.type, _obj$2 ),
    { 'btn-link': _vm.link },
    { disabled: _vm.disabled && _vm.tag !== 'button' }
  ],attrs:{"type":_vm.tag === 'button' ? _vm.nativeType : '',"disabled":_vm.disabled || _vm.loading},on:{"click":_vm.handleClick}},[_vm._t("loading",[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }