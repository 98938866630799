var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"true":"","link":{
          name: 'Dashboards',
          icon: 'ni ni-shop text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Alternative', path: '/alternative' }}})],1),(_vm.roles)?_c('sidebar-item',{attrs:{"opened":"","link":{
          name: 'Examples (API)',
          icon: 'ct-image-icon',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'User Profile', path: '/examples/user-profile' }}}),(_vm.roles.includes('admin'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Role Management',
            path: '/examples/role-management/list-roles',
          }}}):_vm._e(),(_vm.roles.includes('admin'))?_c('sidebar-item',{attrs:{"link":{
            name: 'User Management',
            path: '/examples/user-management/list-users',
          }}}):_vm._e(),(_vm.roles.includes('admin') || _vm.roles.includes('creator'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Category Management',
            path: '/examples/category-management/list-categories',
          }}}):_vm._e(),(_vm.roles.includes('admin') || _vm.roles.includes('creator'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Tag Management',
            path: '/examples/tag-management/list-tags',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Item Management',
            path: '/examples/item-management/list-items',
          }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Pages',
          icon: 'ni ni-ungroup text-orange',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Pricing', path: '/pricing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Lock', path: '/lock' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Timeline', path: '/pages/timeline' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Profile', path: '/pages/user' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Components',
          icon: 'ni ni-ui-04 text-info',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Buttons', path: '/components/buttons' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cards', path: '/components/cards' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Grid', path: '/components/grid-system' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Notifications', path: '/components/notifications' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Icons', path: '/components/icons' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Typography', path: '/components/typography' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Multi Level' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Third level menu', path: '#!' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Just another link', path: '#a' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'One last link', path: '#b' }}})],1)],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Forms',
          icon: 'ni ni-single-copy-04 text-pink',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Elements', path: '/forms/elements' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Components', path: '/forms/components' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Validation', path: '/forms/validation' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Tables',
          icon: 'ni ni-align-left-2 text-default',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Tables', path: '/tables/regular' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Sortable', path: '/tables/sortable' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Paginated Tables', path: '/tables/paginated' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Maps',
          icon: 'ni ni-map-big text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Google', path: '/maps/google' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Vector', path: '/maps/vector' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Widgets',
          icon: 'ni ni-archive-2 text-green',
          path: '/widgets',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Charts',
          icon: 'ni ni-chart-pie-35 text-info',
          path: '/charts',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Calendar',
          icon: 'ni ni-calendar-grid-58 text-red',
          path: '/calendar',
        }}}),_c('sidebar-item',{staticClass:"btn-sm btn-danger",attrs:{"link":{
          name: 'Purchase now',
          icon: 'fas fa-download mr-2',
          target: '_blank',
          isRoute: true,
          path:
            'https://www.creative-tim.com/product/vue-argon-dashboard-pro-laravel',
        }}}),_c('sidebar-item',{staticClass:"btn-sm btn-info",attrs:{"link":{
          name: 'Get free demo',
          icon: 'fas fa-gift',
          target: '_blank',
          isRoute: true,
          path:
            'https://www.creative-tim.com/product/vue-argon-dashboard-laravel',
        }}}),_c('sidebar-item',{staticClass:"btn-sm btn-dark",attrs:{"link":{
          name: 'Star us on Github',
          icon: 'fab fa-github',
          target: '_blank',
          isRoute: true,
          path:
            'https://github.com/creativetimofficial/ct-vue-argon-dashboard-pro-laravel',
        }}}),_c('sidebar-item',{staticClass:"btn-sm btn-neutral",attrs:{"link":{
          name: 'Documentation',
          icon: 'fas fa-file-alt',
          target: '_blank',
          isRoute: true,
          path:
            'https://vue-argon-dashboard-pro-laravel.creative-tim.com/documentation/',
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }